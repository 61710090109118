import bus from '@/utils/bus';
import modalBox from '@/components/form-modal/modal-tools';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import ls from '@/utils/local-storage';
import { createVNode, reactive } from 'vue';
import { message, Modal } from 'ant-design-vue';
import {
  create_adviser_user_account,
  create_todoevent,
  delete_adviser_end_date,
  delete_adviser_user_account,
  delete_person_to_adviser_relation,
  enable_adviser_account,
  reset_adviser_password,
  send_adviser_user_account_email,
  update_adviser,
} from '@/api/cerp-model';
import cerp_adviser_editModal from '@/components/page-model/cerp/cerp-adviser-editModal.ts';
import { query_person_for_cmes_list } from '@/api/sys-model';
import {
  fields_todoevent,
  options_todoevent,
  settings_todoevent,
} from '@/components/page-model/cerp/cerp-todoevent-editModal';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const event_obj = bus;
const trim = (str: any) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
export const btn_update_adviser = {
  cb: (data: any, t: any) => {
    const {
      settings_adviser,
      fields_adviser,
      fields_adviser_update,
      options_adviser,
      baseColumns,
    } = cerp_adviser_editModal(t, data);
    let is_update = false;
    const tag_ids: any = [];
    if(data.tags && data.tags.length > 0){
      data.tags.map((item: any) => {
        tag_ids.push(item.id);
      })
    }
    const editModal = reactive({
      visible: true,
      title: t('修改顾问信息'),
      loading: false,
      form: {
        settings: settings_adviser,
        fields: fields_adviser_update,
        rules: {},
        model: reactive({
          org_id: current_org?.id,
          is_active: true,
          adviser_id: data.id,
          title: data.title,
          first_name: data.first_name,
          last_name: data.last_name,
          other_name: data.other_name,
          birth_date: data.birth_date,
          nationality: data.nationality,
          pay_currency_id: data.pay_currency_id,
          phone: data.phone,
          mobile: data.mobile,
          personal_email: data.personal_email,
          work_email: data.work_email,
          wechat: data.wechat,
          whats_app: data.whats_app,
          qq: data.qq,
          end_date: data?.end_date1,
          user_email_template_id: data?.user_email_template_id,
          tag_ids: tag_ids,
          skypelid: data.skypelid,
          person_type_id: data.person_type_id,
          status: data.status,
          employee_number: data.employee_number,
          home_address_id: data.home_address_id,
          person_note: data.person_note,
          work_address_id: data.work_address_id,
          creater_id: data.creater_id,
          cc_bank_account_id: data.cc_bank_account_id,
        }),
        watch: {
          creater_id: (data: any) => {
            query_person_for_cmes_list({
              org_id: current_org?.id,
              ids: [data.creater_id],
            }).then((res: any) => {
              data['creater'] = res.data[0].realname;
            });
          },
          status: (data: any) => {
            is_update = true;
          },
          mobile: (data: any) => {
            if (data?.mobile && data.mobile.includes('+')) {
              data.mobile = data.mobile.replace(/\+/g, '00');
            }
          },
        },
      },
      options: options_adviser,
      ok: (payload: any) => {
        return new Promise(resolve => {
          // if(is_update && data.end_date){
          //   notification.error({
          //     message: t('提示'),
          //     description: '请先删除顾问结束日期',
          //   });
          //   return false;
          // }
          editModal.loading = true;
          update_adviser({
            ...payload,
            work_address_id: payload.work_address_id ? payload.work_address_id : '',
            first_name: trim(payload.first_name),
            last_name: trim(payload.last_name),
            phone: payload.phone?.trim(),
            mobile: payload.mobile?.trim(),
          }).then((res: any) => {
              message.success(t('修改成功'));
            event_obj.emit('update_adviser', res);
              resolve(null);
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    });
    modalBox(editModal);
  },
};

export const btn_dle_adviser = {
  cb: (data: any, t: any) => {
    Modal.confirm({
      title: t('是否删除顾问结束日期') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          delete_adviser_end_date({
            org_id: current_org.id,
            adviser_id: data.id,
          }).then((res: any) => {
            message.success(t('删除成功'));
            data.end_date2 = data.end_date;
            Object.assign(data, res);
            data.end_date1 = res.end_date;
            data.end_date = res.end_date2;
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
      },
    });
  }
}
export const btn_create_todoevent = {
  cb: (data: any, t: any) => {
    const editModal = reactive({
      visible: true,
      title: t('新建待办事项'),
      loading: false,
      form: {
        settings: settings_todoevent,
        fields: fields_todoevent(t),
        rules: {},
        model: reactive({
          org_id: current_org?.id,
          is_active: true,
          adviser_id: data.id,
        }),
      },
      options: options_todoevent(t),
      ok: (payload: any) => {
        return new Promise(resolve => {
          editModal.loading = true;
          create_todoevent(payload)
            .then(() => {
              message.success(t('新建成功'));
              resolve(null);
            })
            .finally(() => {
              editModal.loading = false;
            });
        });
      },
    });
    modalBox(editModal);
  },
};

export const btn_create_adviser = {
  cb: (data: any, t: any) => {
    const tag_ids: any = [];
    if (data.tags && data.tags.length > 0) {
      data.tags.map((item: any) => {
        tag_ids.push(item.id);
      });
    }
    const editModal = reactive({
      visible: true,
      title: t('创建账号'),
      loading: false,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'remoteselect',
            name: 'tag_ids',
            label: '标签',
            label_i18n: '标签',
            disabled: false,
            placeholder: '请选择标签',
            placeholder_i18n: '请选择标签',
            datasourceType: 'remote',
            mode: 'multiple',
            allowClear: true,
            labelKey: 'name',
            valueKey: 'id',
            modalType: 'baseinfo-tag',
          },
          {
            type: 'remoteselect',
            name: 'user_email_template_id',
            label: '用户邮箱模板',
            label_i18n: '用户邮箱模板',
            disabled: false,
            placeholder: '请选择用户邮箱模板',
            placeholder_i18n: '请选择用户邮箱模板',
            datasourceType: 'remote',
            mode: 'default',
            allowClear: true,
            modalType: 'baseinfo-emailtemplate',
          },
          {
            type: 'input',
            name: 'username',
            label: '用户名',
            label_i18n: '用户名',
            disabled: false,
            placeholder: '请填写用户名',
            placeholder_i18n: '请填写用户名',
            datasourceType: 'remote',
            allowClear: false,
          },
        ],
        rules: {
          username: [{ required: true, message: t('请填写用户名') }],
          tag_ids: [{ required: true, message: t('请选择标签') }],
          user_email_template_id: [{ required: true, message: t('请选择用户邮箱模板') }],
        },
        model: reactive({
          org_id: current_org?.id,
          is_active: true,
          tag_id: tag_ids,
          nationality: data.nationality,
          home_address_id: data.home_address_id,
          work_address_id: data.work_address_id,
          user_email_template_id: data.user_email_template_id,
          username: data.personal_email ? data.personal_email : data.work_email,
          adviser_id: Number(data.id),
        }),
      },
      options: {},
      ok: (payload: any) => {
        return new Promise(resolve => {
          editModal.loading = true;
          update_adviser({
            ...payload,
          }).then(() => {
            create_adviser_user_account({
              ...payload,
            })
              .then((res: any) => {
                message.success(t('创建成功'));
                Object.assign(data, res);
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          })
        });
      },
    });
    modalBox(editModal);
  },
};

export const btn_dle_adviser_suer = {
  cb: (record: any, t: any) => {
    Modal.confirm({
      title: t('是否删除账号') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          delete_adviser_user_account({
            org_id: current_org.id,
            adviser_id: record.id,
          }).then((res: any) => {
            message.success(t('删除成功'));
            Object.assign(record, res);
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  },
};
export const btn_recover_adviser_suer = {
  cb: (record: any, t: any) => {
    Modal.confirm({
      title: t('是否恢复账号') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          enable_adviser_account({
            org_id: current_org.id,
            adviser_id: record.id,
          }).then((res: any) => {
            message.success(t('恢复成功'));
            Object.assign(record, res);
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  },
};

export const btn_send_adviser_user = {
  cb: (record: any, t: any) => {
    Modal.confirm({
      title: t('确认发送邮件') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          send_adviser_user_account_email({
            org_id: current_org.id,
            adviser_id: record.id,
          }).then((res: any) => {
            message.success(t('发送成功'));
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  },
};

export const btn_reset_adviser_suer = {
  cb: (record: any, t: any) => {
    Modal.confirm({
      title: t('是否重置密码') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          reset_adviser_password({
            org_id: current_org.id,
            adviser_id: record.id,
          }).then((res: any) => {
            message.success(t('重置成功'));
            Object.assign(record, res);
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  },
};

export const btn_unbound_weixin = {
  cb: (data: any, t: any) => {
    Modal.confirm({
      title: t('是否小程序解绑') + '?',
      icon: createVNode(ExclamationCircleOutlined),
      onOk() {
        return new Promise(resolve => {
          delete_person_to_adviser_relation({
            org_id: current_org.id,
            adviser_id: data.id,
          }).then((res: any) => {
            message.success(t('解绑成功'));
            Object.assign(data, res);
            data.icon_url = '';
            data.nick_name = '';
            data.wx_bind = false;
            resolve(null);
          });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  },
};
