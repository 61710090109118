const True = true;
const False = false;
export const settings_todoevent = {
  labelAlign: 'right',
  layout: 'vertical',
  col: [],
};
export const fields_todoevent = (t: any) => {
  return [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: '标题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: '请填写标题',
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: '内容',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: '请填写内容',
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: '是否完成',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: '顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'datepicker',
      name: 'alarm_time',
      label: '提醒时间',
      label_i18n: '提醒时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:00',
      placeholder: '请填写提醒时间',
    },
    {
      type: 'number',
      name: 'alarm_number',
      label: '提醒间隔天数',
      label_i18n: '提醒间隔天数',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: 0,
      placeholder: '请填写提醒间隔天数',
      placeholder_i18n: '请填写提醒间隔天数',
    },
    {
      type: 'number',
      name: 'priority',
      label: '优先等级',
      label_i18n: '优先等级',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: 0,
      placeholder: '请填写优先等级',
      placeholder_i18n: '请填写优先等级',
    },
  ];
};
export const options_todoevent = (t: any) => {
  return {
    is_finished: [
      { value: True, name: t('完成') },
      { value: False, name: t('未完成') },
    ],
  };
};
