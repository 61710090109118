<template>
  <page-container :title="nsfile_id + '#' + '请款单批量创建'">
    <template #tags>
      <span style="font-weight: bolder; font-size: 20px">
        {{ t('创建') }}【
        <span style="color: #00dd00">
          <check-circle-outlined style="color: #00dd00" />
          {{ create_number }}
        </span>
        <span style="color: #f5222d">
          <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />
          {{ dataList.length - create_number }}
        </span>
        】
      </span>
      <span style="font-weight: bolder; font-size: 20px; margin-left: 20px">
        {{ t('生成PDF') }}【
        <span style="color: #00dd00">
          <check-circle-outlined style="color: #00dd00" />
          {{ pdf_num }}
        </span>
        <span style="color: #f5222d">
          <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />
          {{ dataList.length - pdf_num }}
        </span>
        】
      </span>
      <span style="font-weight: bolder; font-size: 20px; margin-left: 20px">
        {{ t('已发送') }}【
        <span style="color: #00dd00">
          <check-circle-outlined style="color: #00dd00" />
          {{ sted_num }}
        </span>
        <span style="color: #f5222d">
          <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />
          {{ dataList.length - sted_num }}
        </span>
        】
      </span>
      <a-select
        v-model:value="select_company"
        :dropdownMatchSelectWidth="false"
        style="width: 300px; margin-left: 10px"
        @change="selectCompany"
      >
        <a-select-option v-for="(obj, index) in company_list" :key="index" :value="obj.id">
          {{ obj.name }}
        </a-select-option>
      </a-select>
    </template>
    <div :style="'height: ' + tableHeight.y + 'px;overflow-y: scroll'">
      <template v-for="obj in dataList" :key="obj.index">
        <super-details
          :cc_company="select_company"
          :cc_company_name="select_company_name"
          :invoiceObj="obj"
          :invoice-create="obj.is_create"
          :pdf-num="pdf__num"
          @ok="is_create_number(obj)"
          @cancel="del_create_number(obj)"
          @checked="
            ischecked => {
              obj.checked = ischecked;
            }
          "
        />
      </template>
    </div>
    <div>
      <a-card style="margin-top: 10px" :bordered="false">
        <a-checkbox v-model:value="baseState.selectAll" @change="selectAll">
          {{ t('全选') }}
        </a-checkbox>
        <a-button
          :loading="pdf_loading"
          style="margin-left: 15px"
          :disabled="create_number === dataList.length"
          type="primary"
          size="small"
          @click="create_invoice"
        >
          {{ t('批量创建请款单') }}
        </a-button>
        <a-button
          :loading="pdf_loading1"
          style="margin-left: 15px"
          :disabled="is_pdf"
          type="primary"
          size="small"
          @click="creat_pdf"
        >
          {{ t('批量生成PDF') }}
        </a-button>
        <a-button
          :loading="email1_loading"
          style="margin-left: 15px"
          :disabled="is_download_zip || is_email"
          type="primary"
          size="small"
          @click="send_email1"
        >
          {{ t('批量手动发送') }}
        </a-button>
        <a-button
          :loading="email_loading"
          style="margin-left: 15px"
          type="primary"
          size="small"
          :disabled="is_download_zip || is_email"
          @click="send_email"
        >
          {{ t('批量发送邮件') }}
        </a-button>
        <a-button
          style="margin-left: 15px"
          type="primary"
          size="small"
          :disabled="is_download_zip"
          @click="download_zip"
        >
          {{ t('批量下载PDF') }}
        </a-button>
      </a-card>
    </div>
  </page-container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import {
  bulk_create_invoice,
  bulk_create_invoice_pdf,
  bulk_send_invoice_by_email,
  bulk_send_invoice_by_manul,
  get_contract_by_service_month,
  import_invoice,
} from '@/api/cerp-model';
import { useStore } from 'vuex';
import { btn_update_adviser } from '@/views/profile/basic/basic.ts';
import SuperDetails from '@/components/super-details/index.vue';
import { event_obj } from '@/views/profile/basic-invoice/index1';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, notification } from 'ant-design-vue';
import { query_person_to_company_list } from '@/api/baseinfo-model';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const store = useStore();
    const activeKey = ref<string>('合同');
    const baseState = reactive({
      selectAll: false,
    });
    const pdf__num = ref<number>(0);
    const pdf_list_num = ref<number>(0);
    const pdf_loading = ref(false);
    const pdf_loading1 = ref(false);
    const is_download_zip = ref(true);
    const is_email = ref(false);
    const is_pdf = ref(true);
    const is_pdf1 = ref(false);
    const email_loading = ref(false);
    const email1_loading = ref(false);
    const info = computed(() => store.getters['user/info']);
    const select_company: any = ref(null);
    const select_company_name: any = ref(null);
    const current_company = computed(() => store.getters['user/current_company']);
    select_company.value = current_company.value?.id ? current_company.value?.id : null;
    select_company_name.value = current_company.value?.name ? current_company.value?.name : null;
    const nsfile_id = Number(route.params['id']);
    const tableHeight = reactive({ y: window.innerHeight - 290 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 290;
      };
    });
    route.meta.title = Number(route.params['id']) + '# ' + t('请款单批量创建');
    watchEffect(() => {
      if (route.path.split('/')[2] == 'basic-invoice') {
        route.meta.title = Number(route.params['id']) + '# ' + t('请款单批量创建');
      }
    });

    const current_org = store.getters['user/current_org'];
    const dataList: any = ref([]);
    import_invoice({
      nsfile_id: nsfile_id,
      org_id: current_org?.id,
    }).then((res: any) => {
      dataList.value = res;
      dataList.value.forEach((item: any) => {
        item.is_create = false;
        item.adviser_id_list.forEach((item1: any) => {
          item1.invoice = {};
        });
      });
    });

    const company_list: any = ref([]);
    query_person_to_company_list({
      org_id: current_org.value?.id,
      user_id: info.value.id,
    }).then((res: any) => {
      company_list.value.length = 0;
      let ids: any = [];
      let num = 0;
      if (res && res.data && res.data.length > 0) {
        if (res.data[0]?.cc_companys && res.data[0]?.cc_companys.length > 0) {
          res.data[0]?.cc_companys.forEach((item: any) => {
            company_list.value.push({
              name: item.name,
              id: item.id,
            });
            ids += item.id + ',';
            num += 1;
          });
        }
      }
      if (num > 0) {
        company_list.value.reverse();
        company_list.value.push({
          name: '全部公司',
          id: ids,
        });
        company_list.value.reverse();
      }
    });
    const is_create_number = (obj: any) => {
      obj.is_create = true;
    };
    const del_create_number = (obj: any) => {
      obj.is_create = false;
    };
    const create_number: any = computed(() => {
      let num = 0;
      dataList.value.forEach((item: any) => {
        if (item.is_create) {
          num = num + 1;
        }
      });
      return num;
    });
    const sted_num = ref(0);
    const pdf_num = ref(0);
    watchEffect(() => {
      let num1 = 0;
      let num2 = 0;
      dataList.value.forEach((item: any) => {
        item.adviser_id_list.forEach((item1: any) => {
          if (item1.invoice.status === 6) {
            num1++;
          } else if (item1.invoice.status === 2) {
            num1++;
            num2++;
          }
        });
      });
      sted_num.value = num2;
      pdf_num.value = num1;
    });
    const create_invoice = () => {
      let is_create = false;
      let list: any = [];
      let num: any = 0;

      const templist = dataList.value.filter((item: any) => {
        return item.checked;
      });
      const creat_invoice = () => {
        pdf_loading.value = true;
        bulk_create_invoice({
          org_id: current_org?.id,
          data_json_list: JSON.stringify(list),
        })
          .then((res: any) => {
            templist.forEach((item: any) => {
              res.invoice_list.forEach((item1: any) => {
                if (
                  item.adviser_id_list[0].employee_number == item1.adviser__employee_number &&
                  moment(item.service_month).format('YYYY-MM') ==
                    moment(item1.service_month).format('YYYY-MM') &&
                  ((item.invoice_type == 'Expense' && item1.invoice_type == 2) ||
                    (item.invoice_type == 'Salary' && item1.invoice_type == 1) ||
                    (item.invoice_type == 'Overtime' && item1.invoice_type == 4) ||
                    (item.invoice_type == 'Credit Note' && item1.invoice_type == 3))
                ) {
                  item.adviser_id_list[0].invoice = item1;
                  item.adviser_id_list[0].is_create = true;
                }
              });
            });
            dataList.value.forEach((item: any) => {
              res.invoice_list.forEach((item1: any) => {
                if (
                  item.checked &&
                  item.adviser_id_list[0].employee_number == item1.adviser__employee_number &&
                  ((item.invoice_type == 'Expense' && item1.invoice_type == 2) ||
                    (item.invoice_type == 'Salary' && item1.invoice_type == 1) ||
                    (item.invoice_type == 'Overtime' && item1.invoice_type == 4) ||
                    (item.invoice_type == 'Credit Note' && item1.invoice_type == 3))
                ) {
                  item.is_create = true;
                  is_pdf.value = false;
                  if (item.invoice_type == 'Salary' && item1.invoice_type == 1) {
                    item.adviser_id_list[0].insurance_fee = item1.insurance_fee;
                    item.adviser_id_list[0].bank_charge_cost = item1.bank_charge_cost;
                  }
                  message.success(t('批量创建成功'));
                }
              });
            });
            if (res.msg) {
              notification.error({
                message: t('提示'),
                description: res.msg,
              });
            }
            pdf__num.value++;
          })
          .finally(() => {
            pdf_loading.value = false;
            // dataList.value.forEach((item: any) => {//选中框恢复`未选中`状态
            //   item.checked =false;
            // })
            // baseState.selectAll = false;
          });
      };
      templist.forEach((item: any) => {
        if (item.adviser_id_list.length === 0 || item.adviser_id_list.length > 1) {
          message.error(item.adviser + t('未选择，或选择多个'));
          return;
        } else {
          if (!item.service_month) {
            notification.error({
              message: t('提示'),
              description: item.adviser + t('服务月份不能为空，请修改上传导入文件'),
            });
            return;
          } else if (!item.invoice_start_date) {
            notification.error({
              message: t('提示'),
              description: item.adviser + t('请款起始日期不能为空，请修改上传导入文件'),
            });
            return;
          } else if (
            !(item?.invoice_type == 'Expense'
              ? 2
              : item?.invoice_type == 'Salary'
              ? 1
              : item?.invoice_type == 'Overtime'
              ? 4
              : item?.invoice_type == 'Credit Note'
              ? 3
              : null)
          ) {
            notification.error({
              message: t('提示'),
              description:
                item.adviser + t('请款类型') + item?.invoice_type + t('有误，请修改上传导入文件'),
            });
            return;
          } else {
            if (item.adviser_id_list[0].contract_id) {
              num = num + 1;
              const invoice_objs: any = {
                customfield_list: item.customfield_list,
                adviser_id: item.adviser_id_list[0]?.id,
                contract_id: item.adviser_id_list[0]?.contract_id,
                overtime_rate: item?.overtime_rate
                  ? item?.overtime_rate
                  : item.adviser_id_list[0]?.overtime_rate,
                overtime_amount: item?.overtime_amount,
                service_month: item?.service_month,
                invoice_start_date: item?.invoice_start_date,
                basic_amount: item?.basic_amount,
                work_unit: item?.work_unit,
                accommodation_unit: item?.accommodation_unit,
                accommodation_amount: item?.accommodation_amount,
                overtime: item?.overtime,
                remark: item?.remark,
                payment_term: item?.payment_term,
                bank_charge_cost:
                  item?.invoice_type == 'Salary'
                    ? item?.adviser_id_list[0]?.is_current
                      ? item?.adviser_id_list[0]?.company_bank_charge
                      : item?.adviser_id_list[0]?.cc_company__bank_charge
                    : 0,
                insurance_fee:
                  item?.invoice_type == 'Salary' ? item?.adviser_id_list[0]?.c_insurance_fee : 0,
                invoice_type:
                  item?.invoice_type == 'Expense'
                    ? 2
                    : item?.invoice_type == 'Salary'
                    ? 1
                    : item?.invoice_type == 'Overtime'
                    ? 4
                    : item?.invoice_type == 'Credit Note'
                    ? 3
                    : null,
              };
              list.push(invoice_objs);
              if (num == templist.length) {
                creat_invoice();
              }
            } else {
              get_contract_by_service_month({
                org_id: current_org?.id,
                service_month: item.service_month,
                adviser_id: item.adviser_id_list[0].id,
                cc_company_id: current_company.value?.id,
              }).then((res: any) => {
                if (res && res.id) {
                  num = num + 1;
                  const invoice_objs: any = {
                    customfield_list: item.customfield_list,
                    adviser_id: item.adviser_id_list[0]?.id,
                    contract_id: res.id,
                    service_month: item?.service_month,
                    invoice_start_date: item?.invoice_start_date,
                    basic_amount: item?.basic_amount,
                    work_unit: item?.work_unit,
                    accommodation_unit: item?.accommodation_unit,
                    accommodation_amount: item?.accommodation_amount,
                    overtime: item?.overtime,
                    overtime_rate: item?.overtime_rate
                      ? item?.overtime_rate
                      : item.adviser_id_list[0]?.overtime_rate,
                    overtime_amount: item?.overtime_amount,
                    remark: item?.remark,
                    bank_charge_cost:
                      item?.invoice_type == 'Salary'
                        ? item?.adviser_id_list[0]?.is_current
                          ? item?.adviser_id_list[0]?.company_bank_charge
                          : item?.adviser_id_list[0]?.cc_company__bank_charge
                        : 0,
                    insurance_fee:
                      item?.invoice_type == 'Salary'
                        ? item?.adviser_id_list[0]?.c_insurance_fee
                        : 0,
                    payment_term: item?.payment_term,
                    invoice_type:
                      item?.invoice_type == 'Expense'
                        ? 2
                        : item?.invoice_type == 'Salary'
                        ? 1
                        : item?.invoice_type == 'Overtime'
                        ? 4
                        : item?.invoice_type == 'Credit Note'
                        ? 3
                        : null,
                  };
                  list.push(invoice_objs);
                  if (num == templist.length) {
                    creat_invoice();
                  }
                } else {
                  notification.error({
                    message: t('提示'),
                    description: item.adviser_id_list[0].full_name + t('无可用合同，请添加'),
                  });
                  return;
                }
              });
            }
          }
        }
      });
    };
    const creat_pdf = () => {
      pdf_loading1.value = true;
      const pdf_list: any = [];
      dataList.value.forEach((item: any) => {
        if (!item.adviser_id_list[0].invoice.bank_account__account_name) {
          notification.error({
            message: t('提示'),
            description: item.adviser_id_list[0].invoice + ' ' + t('没有CC银行账户，请添加'),
          });
        }
        if (
          item.adviser_id_list &&
          item.adviser_id_list.length == 1 &&
          item.adviser_id_list[0].invoice.status == 1
        ) {
          pdf_list.push(item.adviser_id_list[0].invoice.id);
        }
      });
      pdf_list_num.value = pdf_list.length;
      bulk_create_invoice_pdf({
        org_id: current_org.id,
        invoice_ids: pdf_list,
      })
        .then((res: any) => {
          message.success(t('批量生成PDF成功'));
          pdf__num.value++;
          is_pdf1.value = true;
        })
        .finally(() => {
          pdf_loading1.value = false;
        });
    };
    event_obj.on('is_pdf', () => {
      let num = 0;
      dataList.value.forEach((item: any) => {
        if (
          (item.adviser_id_list &&
            item.adviser_id_list.length == 1 &&
            item.adviser_id_list[0] &&
            item.adviser_id_list[0]?.invoice &&
            item.adviser_id_list[0]?.invoice.pdf_status == 2) ||
          item.adviser_id_list[0]?.invoice.pdf_status == 3
        ) {
          num = num + 1;
        }
        if (num >= pdf_list_num.value && is_pdf1.value) {
          is_download_zip.value = false;
          is_pdf.value = true;
        }
      });
    });
    event_obj.on('is_email', () => {
      let num = 0;
      dataList.value.forEach((item: any) => {
        if (
          item.adviser_id_list &&
          item.adviser_id_list.length == 1 &&
          item.adviser_id_list[0] &&
          item.adviser_id_list[0]?.invoice &&
          item.adviser_id_list[0]?.invoice.email_record__status == 1
        ) {
          num = num + 1;
        }
        if (num >= pdf_list_num.value && is_pdf1.value) {
          is_email.value = true;
        }
      });
    });

    const send_email = () => {
      email_loading.value = true;
      const email_list: any = [];
      dataList.value.forEach((item: any) => {
        if (
          item.adviser_id_list &&
          item.adviser_id_list.length == 1 &&
          item.adviser_id_list[0].invoice.status == 6
        ) {
          email_list.push(item.adviser_id_list[0].invoice.id);
        }
      });
      bulk_send_invoice_by_email({
        org_id: current_org.id,
        invoice_ids: email_list,
      })
        .then((res: any) => {
          message.success(t('批量发邮件成功'));
          pdf__num.value++;
        })
        .finally(() => {
          email_loading.value = false;
        });
    };
    const send_email1 = () => {
      email1_loading.value = true;
      const email_list: any = [];
      dataList.value.forEach((item: any) => {
        if (
          item.adviser_id_list &&
          item.adviser_id_list.length == 1 &&
          item.adviser_id_list[0].invoice.status == 6
        ) {
          email_list.push(item.adviser_id_list[0].invoice.id);
        }
      });
      bulk_send_invoice_by_manul({
        org_id: current_org.id,
        invoice_ids: email_list,
      })
        .then((res: any) => {
          message.success(t('批量手动发邮件成功'));
          pdf__num.value++;
        })
        .finally(() => {
          email1_loading.value = false;
        });
    };
    const download_zip = () => {
      const file_ids: any = [];
      dataList.value.forEach((item: any) => {
        if (
          item.adviser_id_list &&
          item.adviser_id_list.length == 1 &&
          item.adviser_id_list[0].invoice.pdf_status == 3
        ) {
          file_ids.push(item.adviser_id_list[0].invoice.files_json.file_id);
        }
      });
      const url: string = '/nsbcs/download_zip_file?fileids=' + file_ids;
      window.open(url, '_blank');
    };
    const selectAll = (checkedValue: any) => {
      baseState.selectAll = checkedValue.target.checked;
      dataList.value.forEach((item: any) => {
        item.checked = baseState.selectAll;
      });
    };
    const selectCompany = (value: any) => {
      select_company.value = value;
      company_list.value.forEach((item: any) => {
        if (item.id == value) {
          select_company_name.value = item.name;
        }
      });
    };

    return {
      t,
      baseState,
      dataList,
      pdf_num,
      pdf__num,
      sted_num,
      create_number,
      tableHeight,
      activeKey,
      nsfile_id,
      pdf_loading,
      pdf_loading1,
      email1_loading,
      email_loading,
      is_pdf,
      is_email,
      is_download_zip,
      company_list,
      select_company,
      select_company_name,
      creat_pdf,
      download_zip,
      send_email,
      send_email1,
      is_create_number,
      create_invoice,
      del_create_number,
      btn_update_adviser,
      selectAll,
      selectCompany,
    };
  },
  components: {
    SuperDetails,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  },
});
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 16px;
  color: @text-color;
  font-weight: 500;
  font-size: 16px;
}
</style>
